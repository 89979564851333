var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"filter-wrap flex-between"},[_c('SearchFilter',{attrs:{"search":_vm.listQuery.SearchStr},on:{"handleFilter":_vm.handleFilter,"update:search":function($event){return _vm.$set(_vm.listQuery, "SearchStr", $event)}}})],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"max-height":_vm.pageH - 140,"border":"","fit":"","highlight-current-row":"","data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"label":"标题","show-overflow-tooltip":true,"prop":"title"}}),_c('el-table-column',{attrs:{"show-overflow-tooltip":true,"label":"内容"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(row.content)}})]}}])}),_c('el-table-column',{attrs:{"prop":"messageType","label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.messageType == 1 ? '系统消息' : '待办消息'))])]}}])}),_c('el-table-column',{attrs:{"prop":"notifyUserName","label":"接收人","show-overflow-tooltip":true,"width":"80"}}),_c('el-table-column',{attrs:{"prop":"createBy","label":"创建人","show-overflow-tooltip":true,"width":"80"}}),_c('el-table-column',{attrs:{"prop":"createTime","width":"150","label":"创建时间"}}),_c('el-table-column',{attrs:{"prop":"status","label":"状态","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"badge",class:[row.status<2 ? 'bg-danger': 'bg-success']},[_vm._v(" "+_vm._s(row.status<2 ? '未读' : '已读')+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.delAssest(row.id)}}},[_c('el-tooltip',{attrs:{"content":"删除","placement":"top","effect":"light"}},[_c('i',{staticClass:"el-icon-delete"})])],1)]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total>0),expression:"total>0"}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "pageSize", $event)},"pagination":_vm.getList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }