<template>
  <div>

    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter @handleFilter="handleFilter" :search.sync="listQuery.SearchStr" />
      </div>
      <el-table
        v-loading="loading"
        :max-height="pageH - 140"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          label="标题"
          :show-overflow-tooltip="true"
          prop="title"
        />
        <el-table-column
          :show-overflow-tooltip="true"
          label="内容"
        >
          <template slot-scope="{row}">
            <span v-html="row.content" />
          </template>
        </el-table-column>
        <el-table-column
          prop="messageType"
          label="类型"
        >
          <template slot-scope="{row}">
            <span>{{ row.messageType == 1 ? '系统消息' : '待办消息' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="notifyUserName"
          label="接收人"
          :show-overflow-tooltip="true"
          width="80"
        />
        <el-table-column
          prop="createBy"
          label="创建人"
          :show-overflow-tooltip="true"
          width="80"
        />
        <el-table-column
          prop="createTime"
          width="150"
          label="创建时间"
        />
        <el-table-column
          prop="status"
          label="状态"
          width="80"
        >
          <template slot-scope="{row}">
            <div
              class="badge"
              :class="[row.status<2 ? 'bg-danger': 'bg-success']"
            >
              {{ row.status<2 ? '未读' : '已读' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80px"
        >
          <template slot-scope="{row}">
            <el-link
              :underline="false"
              type="primary"
              @click="delAssest(row.id)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
              v-show="total>0"
              :total="total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.pageSize"
              @pagination="getList"
      />
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
// eslint-disable-next-line import/no-cycle
import { deleteAssets } from '@/api/enterprise/assest'
import { listData, DeleteMessageByIds } from '@/api/system/notify/notify'
import { error, success } from '@core/utils/utils'

export default {
  components: {
    pagination,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    delAssest(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const ids = []
        ids.push(id)
        DeleteMessageByIds({ ids }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.dialogVisible = false
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    saveDialogShow(type, assestId) {
      this.$refs.thridSave.dialogVisible = true
      // eslint-disable-next-line eqeqeq
      if (type == 2) {
        this.$refs.thridSave.form.id = assestId
      }
    },
    getList() {
      this.loading = true
      listData(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
